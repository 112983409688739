

.home-container{
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    background-color: #37474F;
}

.home-content-container{
    height: 100%;
    display: flex;
}

.home-content-title{
    display:flex;
    align-items: center;
    overflow: hidden;
}

.home-content-title-text{
    margin-left: 14px;
}

.home-content-divider{
    background-color: #263238;
    margin-top: 6px;
    margin-left: 1rem;
    margin-right: 1rem;
    height: 4px;
    border-radius: 4px;
}

.home-searchbar-container{
    margin-top: 20px;
    background-color: #455A64;
    border-radius: 4px;
    margin-left: 1rem;
    margin-right: 1rem;
    box-shadow: 1px 1px 4px #263238;
}
